import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import useIsMobile from '../../../hooks/useIsMobile'
import {
  mobileVW,
  font,
  colors,
  desktopBreakpoint,
  headerHeight,
} from '../../../styles'

import { debounce, getTopOffset } from '../../../utils'

const MenuWrapper = styled.ul`
  width: 100%;
  background-color: ${colors.beigeUltraLight};
  padding: ${mobileVW(15)} 0;
  overflow: scroll;
  display: flex;
  position: sticky;
  top: ${headerHeight.mobile}px;
  z-index: 9;
  @media (min-width: ${desktopBreakpoint}) {
    justify-content: center;
    padding: 15px 0;
    top: ${headerHeight.desktop}px;

    a:last-of-type {
      margin-right: 0;
    }

    & + .section_block {
      padding-top: 60px;
  }
`
const Menu = styled.a`
  color: ${colors.brownDark};
  font-family: ${font.larsseit};
  font-size: ${mobileVW(16)};
  line-height: ${mobileVW(22)};
  margin-left: ${mobileVW(24)};
  white-space: nowrap;
  padding: ${mobileVW(12)} ${mobileVW(17)};
  &:active,
  &.is-active {
    color: white;
    background: ${colors.orangeNew};
    border-radius: ${mobileVW(50)};
  }
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 16px;
    line-height: 22px;
    margin: 0 40px 0 0;
    padding: 12px 20px;
    position: relative;
    &:active,
    &:hover {
      color: white;
      background: ${colors.orangeNew};
      border-radius: 50px;
    }
  }
`

function getActiveIndex(ids) {
  const index = ids.indexOf(window.location.hash.substring(1))

  return ~index ? index : 0
}

const ScrollMenu = ({ titles, ids }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const isMobile = useIsMobile()

  const observerOptionsTop = {
    root: null,
    rootMargin: isMobile ? '0px 0px -80% 0px' : '0px 0px -95% 0px',
    threshold: 0.01,
  }

  const observerOptionsBottom = {
    root: null,
    rootMargin: '-95% 0px 0px 0px',
    threshold: 0.01,
  }

  const observerCallback = entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const index = ids.indexOf(entry.target.dataset.section)

        setActiveIndex(~index ? index : 0)
      }
    })
  }

  useEffect(() => {
    setActiveIndex(getActiveIndex(ids))

    const topObserver = new IntersectionObserver(
      observerCallback,
      observerOptionsTop,
    )
    const bottomObserver = new IntersectionObserver(
      observerCallback,
      observerOptionsBottom,
    )

    ids.forEach(id => {
      const anchorTop = document.querySelector(
        `[data-anchor="top"][data-section="${id}"]`,
      )
      const anchorBottom = document.querySelector(
        `[data-anchor="bottom"][data-section="${id}"]`,
      )

      if (anchorTop) topObserver.observe(anchorTop)
      if (anchorBottom) bottomObserver.observe(anchorBottom)
    })

    const scrollCallback = debounce(e => {
      const topAnchorPositions = ids.map(id => {
        const anchor = document.querySelector(
          `[data-anchor="top"][data-section="${id}"]`,
        )

        return getTopOffset(anchor)
      })

      const min = Math.max(
        ...topAnchorPositions.filter(y => window.scrollY > y),
      )
      const max = Math.min(
        ...topAnchorPositions.filter(y => window.scrollY < y),
      )
      const median = (min + max) / 2 - window.innerHeight * 0.05 // (window.innerHeight * 0.05) is used to include the rootMargin

      const position = window.scrollY > median ? max : min
      const index = topAnchorPositions.indexOf(position)

      setActiveIndex(index)
    }, 200)

    scrollCallback()

    document.addEventListener('scroll', scrollCallback)

    return () => {
      ids.forEach(id => {
        const anchorTop = document.querySelector(
          `[data-anchor="top"][data-section="${id}"]`,
        )
        const anchorBottom = document.querySelector(
          `[data-anchor="bottom"][data-section="${id}"]`,
        )

        if (anchorTop) topObserver.unobserve(anchorTop)
        if (anchorBottom) bottomObserver.unobserve(anchorBottom)
      })

      document.removeEventListener('scroll', scrollCallback)
    }
  }, [isMobile])

  return (
    <MenuWrapper>
      {titles.map((title, i) => (
        <Menu
          key={i}
          href={`#${ids[i]}`}
          className={i === activeIndex ? `is-active` : ''}
        >
          <span>{title}</span>
        </Menu>
      ))}
    </MenuWrapper>
  )
}

export default ScrollMenu
