import React from 'react'
import Image from 'gatsby-image'
import styled from 'styled-components'
import RichText from '../RichText'
import HeaderSwiper from '../swipers/headerSwiper'

import {
  mobileVW,
  headerHeight,
  font,
  colors,
  desktopBreakpoint,
} from '../../../styles'

const Header = styled.div`
  margin-top: ${headerHeight.mobile}px;
  @media (min-width: ${desktopBreakpoint}) {
    margin-top: ${headerHeight.desktop}px;
  }

  &.subcategory_header {
    display: flex;
    flex-direction: column;
    height: ${mobileVW(520)};
    text-align: center;
    background-color: ${colors.beigeLight};

    .gatsby-image-wrapper {
      height: ${mobileVW(240)};
      @media (min-width: ${desktopBreakpoint}) {
        max-width: 700px;
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    > div {
    }
    @media (min-width: ${desktopBreakpoint}) {
      height: 450px;
      flex-direction: row;
    }
  }

  &.fade_header,
  &.normal_header {
    position: relative;

    @media (min-width: ${desktopBreakpoint}) {
      height: 100vh;
    }

    @media (max-width: ${desktopBreakpoint}) {
      min-height: 100vh;
    }

    .gatsby-image-wrapper {
      height: 100%;

      @media (max-width: ${desktopBreakpoint}) {
        position: absolute !important;
        z-index: -1;
      }
    }

    .header-text_wrapper {
      top: 0;
      height: fit-content;
      width: 100%;
      max-width: none;
      text-align: center;
      padding: 41px;

      form div:last-child {
        height: auto;
      }

      @media (min-width: ${desktopBreakpoint}) {
        height: 100%;
        position: absolute;
      }

      h2 {
        font-family: ${font.ben};
        font-size: 58px;
        padding: 0 30px;
        margin-bottom: 26px;
        @media (min-width: ${desktopBreakpoint}) {
          font-size: 80px;
        }
      }

      p {
        max-width: 640px;
        font-size: 20px;
        line-height: 27px;
        @media (min-width: ${desktopBreakpoint}) {
          line-height: 32px;
        }
      }
    }
  }
`

const StyledImageMobile = styled(Image)`
  width: 100%;

  @media (min-width: ${desktopBreakpoint}) {
    display: none;
  }
`

const StyledImageDesktop = styled(Image)`
  width: 100%;
  display: none;

  @media (min-width: ${desktopBreakpoint}) {
    height: 660px;
    display: block;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${mobileVW(45)} ${mobileVW(16)};
  color: white;
  > h1 {
    font-family: ${font.ben};
    font-size: ${mobileVW(48)};
    padding: 0 ${mobileVW(52)};
    font-weight: normal;
    line-height: 1;
    text-transform: lowercase;
    @media (min-width: ${desktopBreakpoint}) {
      font-size: 80px;
      line-height: 86px;
      padding: 0;
    }
  }
  > p {
    font-family: ${font.larsseit};
    font-size: ${mobileVW(16)};
    margin-top: ${mobileVW(20)};
    line-height: ${mobileVW(22)};
    @media (min-width: ${desktopBreakpoint}) {
      font-size: 20px;
      line-height: 32px;
      margin-top: 40px;
    }
  }
  @media (min-width: ${desktopBreakpoint}) {
    padding: 73px;
    max-width: 640px;
    margin: auto;
  }
`

const Overlay = styled.div`
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: ${mobileVW(160)};
    background: linear-gradient(transparent, rgb(255, 255, 255));
    pointer-events: none;

    @media (min-width: ${desktopBreakpoint}) {
      height: 300px;
    }
  }
`

const HeaderSection = ({
  type,
  image,
  imageMobile,
  description,
  title,
  slides,
  heading,
}) => {
  return (
    <>
      {type != 'slider' && (
        <Header className={`${type}_header`}>
          <StyledImageMobile
            fluid={imageMobile?.fluid}
            alt={imageMobile?.title}
            loading={'eager'}
          />
          <StyledImageDesktop
            fluid={image?.fluid}
            alt={image?.title}
            loading={'eager'}
          />
          {type == 'subcategory' && (
            <TextWrapper>
              <h1>{title}</h1>
              <p>{description}</p>
            </TextWrapper>
          )}
          {(type == 'fade' || type == 'normal') && (
            <>
              <TextWrapper className='header-text_wrapper'>
                <RichText json={heading} />
              </TextWrapper>
              {type == 'fade' && <Overlay />}
            </>
          )}
        </Header>
      )}
      {type == 'slider' && <HeaderSwiper slides={slides} />}
    </>
  )
}

export default HeaderSection
