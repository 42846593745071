import React from 'react'
import Image from 'gatsby-image'
import styled from 'styled-components'
import Button from '../uiComponents/button'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Autoplay } from 'swiper'
import {
  mobileVW,
  headerHeight,
  font,
  colors,
  desktopBreakpoint,
} from '../../../styles'

SwiperCore.use([Pagination])
SwiperCore.use([Autoplay])

const Wrapper = styled.div`
  .swiper-container {
    margin-top: ${headerHeight.mobile}px;
    @media (min-width: ${desktopBreakpoint}) {
      margin-top: 66px;
    }
  }
  .swiper-slide {
    display: flex;
    flex-direction: column;
    height: ${mobileVW(540)};
    overflow: initial;

    .gatsby-image-wrapper {
      height: ${mobileVW(240)};
      display: block;
      img {
        height: 100%;
      }
      @media (min-width: ${desktopBreakpoint}) {
        height: 540px;
      }
    }
    @media (min-width: ${desktopBreakpoint}) {
      flex-direction: row;
      height: 565px;
    }
  }

  .swiper-pagination {
    position: absolute;
    display: flex;
    bottom: 0 !important;
    width: 100%;
    margin: auto;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }

  //bullets are further up on live website (swiper css not loading properly)

  .swiper-pagination-bullet {
    display: inline-block;
    border-radius: 50%;
    width: ${mobileVW(8)};
    height: ${mobileVW(8)};
    background: ${colors.brownDarkest};
    border: none;
    opacity: 1;

    &:not(:last-of-type) {
      margin-right: ${mobileVW(10)};

      @media (min-width: ${desktopBreakpoint}) {
        margin-right: 10px;
      }
    }

    @media (min-width: ${desktopBreakpoint}) {
      width: 8px;
      height: 8px;
    }
  }

  .swiper-pagination-bullet-active {
    width: ${mobileVW(12)};
    height: ${mobileVW(12)};
    background: transparent;
    border: solid ${mobileVW(1)} ${colors.brownDarkest};
    @media (min-width: ${desktopBreakpoint}) {
      width: 12px;
      height: 12px;
      border: solid 1px ${colors.brownDarkest};
    }
  }
`
const StyledImage = styled(Image)`
  display: ${props => (props.mobile ? 'flex' : 'none')};
  @media (min-width: ${desktopBreakpoint}) {
    display: ${props => (props.mobile ? 'none' : 'flex')};
    width: 100%;
    height: 100%;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${mobileVW(280)};
  background-color: ${props => props.background};
  text-align: center;
  color: ${props => props.color};
  position: relative;

  h1 {
    font-family: ${font.ben};
    text-transform: lowercase;
    font-size: ${props =>
      props.increaseFont ? `${mobileVW(48)}` : `${mobileVW(36)}`};
    line-height: ${props =>
      props.increaseFont ? `${mobileVW(52)}` : `${mobileVW(39)}`};
    padding: ${props =>
      props.increaseFont ? `0 ${mobileVW(70)}` : `0 ${mobileVW(16)}`};
    font-weight: normal;
    @media (min-width: ${desktopBreakpoint}) {
      font-size: ${props => (props.increaseFont ? '80px' : '70px')};
      line-height: ${props => (props.increaseFont ? '86px' : '79px')};
      padding: 0 60px;
    }
  }

  p {
    font-family: ${font.larsseit};
    font-size: ${mobileVW(16)};
    line-height: ${mobileVW(19)};
    margin: 0;
    max-width: ${mobileVW(280)};
    margin-top: ${mobileVW(22)};
    @media (min-width: ${desktopBreakpoint}) {
      font-size: 20px;
      line-height: 32px;
      padding: ${props => (props.increaseFont ? '0 112px' : '0 83px')};
      margin-top: 29px;
    }
  }
  @media (min-width: ${desktopBreakpoint}) {
    height: 540px;
    min-width: 580px;
    width: 40%;
  }
`

//this can probably be deleted after redesign merge with new button styles
const ButtonWrapper = styled.div`
  margin-top: ${mobileVW(22)};
  @media (min-width: ${desktopBreakpoint}) {
    margin-top: 29px;
  }
  > div {
    background: white;
    color: ${colors.brownDarkest};
    font-family: ${font.ben};
    text-transform: lowercase;
    font-size: ${mobileVW(18)};
    width: ${mobileVW(201)};
    font-weight: normal;
    @media (min-width: ${desktopBreakpoint}) {
      font-size: 18px;
      width: 201px;
      &:hover {
        background: white;
        color: initial;
      }
    }
  }
`

const Label = styled.div`
  width: ${mobileVW(87)};
  height: ${mobileVW(87)};
  background: white;
  border-radius: 50%;
  position: absolute;
  top: ${mobileVW(-60)};
  right: ${mobileVW(23)};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: lowercase;
  span {
    font-family: ${font.ben};
    text-transform: lowercase;
    font-size: ${mobileVW(32)};
    line-height: 1;
    color: ${colors.brownDarkest};
    padding-bottom: ${mobileVW(5)};
    font-weight: normal;
    @media (min-width: ${desktopBreakpoint}) {
      font-size: 44px;
      padding-bottom: 8px;
    }
  }
  @media (min-width: ${desktopBreakpoint}) {
    width: 120px;
    height: 120px;
    top: 53px;
    right: auto;
    left: -60px;
  }
`

const ImageDesktopWrapper = styled.div`
  display: none;
  @media (min-width: ${desktopBreakpoint}) {
    display: block;
    height: 100%;
    width: 100%;
    flex-basis: 60%;
  }
`
const ImageMobileWrapper = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    display: none;
  }
`

const HeaderSwiper = ({ slides }) => {
  return (
    <Wrapper>
      <Swiper pagination={{ clickable: true }} autoplay={{ delay: 4000 }}>
        {slides.map((slide, i) => (
          <SwiperSlide key={i}>
            {slide.image && (
              <>
                <ImageDesktopWrapper>
                  <StyledImage
                    fluid={slide.image.fluid}
                    loading={
                      i == 0 ? 'eager' : i > 0 && i <= 2 ? 'lazy' : 'auto'
                    }
                  />
                </ImageDesktopWrapper>
                <ImageMobileWrapper>
                  <StyledImage
                    fluid={slide.imageMobile?.fluid || slide.image?.fluid}
                    loading={
                      i == 0 ? 'eager' : i > 0 && i <= 2 ? 'lazy' : 'auto'
                    }
                  />
                </ImageMobileWrapper>
              </>
            )}
            <TextWrapper
              increaseFont={i === 0}
              background={
                slide.backgroundColor ? slide.backgroundColor : '#DDC8B6'
              }
              color={slide.fontColor ? slide.fontColor : '#FFFFFF'}
            >
              <h1>{slide.title}</h1>
              <p>{slide.text}</p>
              {slide.buttonText && slide.article && (
                <ButtonWrapper>
                  <Button
                    ctaType='primary'
                    text={slide.buttonText}
                    slug={slide.article.slug}
                  />
                </ButtonWrapper>
              )}
              {slide.label && (
                <Label>
                  <span>{slide.label}</span>
                </Label>
              )}
            </TextWrapper>
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  )
}

export default HeaderSwiper
